<template>
  <div>
    <!--begin::Dashboard-->
    
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Listado de notificaciones</span
              >
              <span class="text-muted mt-3 font-weight-bold font-size-sm"
                >{{ rows }} Notificaciones enviadas desde el sistema</span
              >
            </h3>
            <div class="card-toolbar">
              <a
                href="#/notifications/new"
                class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                >Nueva notificación</a
              >
              <!-- <a href="#" class="btn btn-danger font-weight-bolder font-size-sm"
                >Create</a
              > -->
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div>
              <div>
                <div class="input-group input-group-lg input-group-solid mb-8">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="la la-search"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg inline"
                    name="address1"
                    placeholder="Busqueda"
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    hide-details
                  />
                </div>
              </div>
              <div class="tab-content position-relative">
                <LoaderPartial v-if="showLoader"></LoaderPartial>
                <!--begin::Table-->
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                  >
                    <thead>
                      <tr class="text-left">
                        <th class="pl-7 hover" @click="sortListFor('title')">
                          <span class="text-dark-75"
                            >Titulo
                            <i
                              class="fa fa-angle-up"
                              aria-hidden="true"
                              v-if="sortingList.title == 'DESC'"
                            ></i>
                            <i
                              class="fa fa-angle-down"
                              aria-hidden="true"
                              v-if="sortingList.title == 'ASC'"
                            ></i>
                          </span>
                        </th>
                        <th class="hover" @click="sortListFor('content')">
                          Contenido
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.content == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.content == 'ASC'"
                          ></i>
                        </th>
                        <!-- <th>Suscripción</th> -->
                        <th>Clientes</th>
                        <th class="hover" @click="sortListFor('createdAt')">
                          Creación
                          <i
                            class="fa fa-angle-up"
                            aria-hidden="true"
                            v-if="sortingList.createdAt == 'DESC'"
                          ></i>
                          <i
                            class="fa fa-angle-down"
                            aria-hidden="true"
                            v-if="sortingList.createdAt == 'ASC'"
                          ></i>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in notifs">
                        <tr v-bind:key="i">
                          <td class="pl-0 py-8">
                            <div class="d-flex align-items-center">
                              <div class="symbol symbol-50 symbol-light mr-4">
                                <span
                                  class="symbol-label"
                                  style="overflow:hidden;"
                                >
                                  <img style="min-width:100%; height:100%;" v-if="item.image"
                                    :src="item.image"
                                  />
                                  <div v-else class="image-input-wrapper d-flex justify-content-center">
                                    <i class="icon-lg flaticon-bell text-primary" />
                                  </div>
                                </span>
                              </div>
                              <div>
                                <a
                                  :href="`#/notifications/${item.id}`"
                                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                  >{{ item.title }}</a
                                >
                              </div>
                            </div>
                          </td>
                          <td>
                            {{item.description}}
                          </td>
                          <td>{{ item.showToAll ? "Todos" : item.coreTenanciesTargets.length}}</td>
                          <td>
                            <span class="text-muted font-weight-bold d-block">{{
                              new Date(item.createdAt)
                                .toLocaleString()
                                .split(" ")[0]
                            }}</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div>
                    <div class="row">
                      <div class="col m-1 ">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          page-click=""
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                      <div class="col m-1 text-right">
                        Elementos por pagina
                        <b-form-select
                          v-model="perPage"
                          :options="optionPerPage"
                          class="mb-3"
                          style="width:70px;"
                          value-field="item"
                          text-field="name"
                          disabled-field="notEnabled"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Table-->
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<style scoped>
table th.hover {
  cursor: pointer;
}
table th.hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

<script>
//import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import LoaderPartial from "@/view/content/LoaderPartial.vue";

export default {
  name: "notifications",
  data() {
    return {
      resource: "notifications",
      search: "",
      notifs: [],
      deletableTenant: {},
      deletableTenantName: "",
      currentPage: 1,
      rows: 0,
      perPage: 5,
      optionPerPage: [5, 10, 25, 50, 100],
      sortingList: {},

      dialog: false,
      showLoader: true
    };
  },
  components: {
    LoaderPartial
  },
  async mounted() {
    await this.getCurrentUser();
    this.getResourceList();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Notificaciones" }]);
    console.log(this.currentUser, this.currentUser.id);
  },
  watch: {
    search: function() {
      this.getResourceList();
    },
    perPage: function() {
      this.getResourceList();
    },
    currentPage: function() {
      this.getResourceList();
    }
  },
  methods: {
    sortListFor(row) {
      if (!this.sortingList[row]) {
        this.sortingList = []; // Clear?
        this.sortingList[row] = "DESC";
      } else if (this.sortingList[row] == "ASC") this.sortingList[row] = "DESC";
      else if (this.sortingList[row] == "DESC") this.sortingList[row] = "ASC";

      this.getResourceList();
    },
    getQuery() {
      let search = this.search.toLowerCase();
      let query = {
        filter: {
          limit: this.perPage,
          skip: this.perPage * (this.currentPage - 1),
          order: Object.keys(this.sortingList).map(
            key => `${key} ${this.sortingList[key]}`
          ),
          where: {
            or: [
              { title: { like: `${search}`, options: "i" } },
              { description: { like: `${search}`, options: "i" } },
            ]
          }
        }
      };
      if (query.filter.order.length === 0) {
        query.filter.order.push("createdAt DESC");
      }
      return query;
    },
    async getCurrentUser() {
      let { data } = await ApiService.get(`admins`, "me");
      console.log("ME", data);
      this.currentUser = data;
    },
    async getResourceCount( retry = 3 ) {
      try {
        let { data } = await ApiService.query(`${this.resource}/count`, {
          where: this.getQuery().filter.where
        });
        this.rows = data.count;
        return data.count;
      } catch (error) {
        if (retry > 0) {
					console.log("(Services) getResourceCount Failed... retrying");
					await setTimeout( async () => {
						await this.getResourceCount(retry-1);
					}, 200);
					return true;
				}
				console.log("(Services) getResourceCount Failed... no more attempts");
				console.log(error);
				throw(error);
      }
    },
    async getResourceList( retry = 3 ) {
      try {
        this.showLoader = true;
        await this.getResourceCount();

        let notifs = await ApiService.query(this.resource, this.getQuery());
        console.log(notifs);
        let data = notifs.data;
        this.notifs = data;
        this.showLoader = false;
        console.log(data);
      } catch (error) {
        if (retry > 0) {
					console.log("(Services) getResourceList Failed... retrying");
					await setTimeout( async () => {
						await this.getResourceList(retry-1);
					}, 200);
					return true;
				}
				console.log("(Services) getResourceList Failed... no more attempts");
				console.log(error);
				throw(error);
      }
    },
    async getResourceRole(id) {
      let { data } = await ApiService.get(`${this.resource}/roles`, id);
      //console.log(data);
      return data;
    },
    async deleteResource(id) {
      ApiService.delete(`${this.resource}/${id}`)
        .then(() => {
          this.getResourceList();

          Swal.fire({
            title: "",
            text: `El usuario ${this.deletableTenantName} ha sido eliminado exitosamente!`,
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
          });
          this.deletableTenant = {};
          this.deletableTenantName = "";
        })
        .catch(() => {
          console.log("Not deleted");
        });
    },
    deleteResourceModal(item) {
      this.deletableTenant = item;
      this.dialog = true;
    },
    async disableResource(id) {
      ApiService.patch(`${this.resource}/${id}`, { active: false })
        .then(() => this.getResourceList())
        .catch(() => {
          console.log("Not disabled");
        });
    },
    async enableResource(id) {
      ApiService.patch(`${this.resource}/${id}`, { active: true })
        .then(() => this.getResourceList())
        .catch(() => {
          console.log("Not enabled");
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  computed: {
    //...mapGetters(["currentUser"]),
  }
};
</script>
